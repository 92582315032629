import React from 'react';

import styles from '~/homepage/Components/HomepageTabbedShopping.module.scss';
import { useHomepageContext } from '~/homepage/Contexts/Homepage.context';
import { TabbedShopping } from '~/components/tabbed-shopping/TabbedShopping';

export const HomepageTabbedShopping = () => {
	const {
		homepageStore: {
			model: {
				tabbedShopping,
				query,
			}
		}
	} = useHomepageContext();

	if (!tabbedShopping) {
		return;
	}

	return (
		<div className={styles.TabbedShopping}>
			<TabbedShopping {...{ ...tabbedShopping, query } } />
		</div>
	);
};
