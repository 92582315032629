import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';

import { Footer } from '~/global/footer/Components/Footer';
import { FullHeader } from '~/global/header/Components/FullHeader';
import { isEngage } from '~/global/global.constants';
import { MagicModal } from '~/components/magic-modal/Components/MagicModal';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useLinkEvent } from '~/hooks/useLinkEvent';
import { LiveChat } from '~/global/live-chat/Components/LiveChat';
import { noop } from '~/util/noop';
import { useGlobalResets } from '~/hooks/useGlobalResets';
import { AdobeTracking } from '~/lib/scripts/AdobeTracking';
import { ReflektionScripts } from '~/lib/reflektion/ReflektionScripts';
import { PublicGlobals } from '~/lib/scripts/PublicGlobals';
import { NewRelic } from '~/lib/scripts/NewRelic';
import { HumanSensor } from '~/lib/scripts/HumanSensor';
import { PersonalizationModal } from '~/personalization/Components/PersonalizationModal';

import styles from '#/layout/main.module.scss';
import toolbarCss from '~/engage/toolbar/Components/engage-toolbar.module.scss';

const EngageToolbarWrapper = dynamic(
	() => import('~/layout/Components/toolbar/EngageToolbarWrapper.pages').then(mod => mod.EngageToolbarWrapper),
);
const DevBeamWrapper = dynamic(
	() => import('~/layout/Components/toolbar/DevBeamWrapper').then(mod => mod.DevBeamWrapper),
	{ ssr: false },
);

type Props = {
	children: React.ReactNode
	pageId?: string
	stickyHeaderMobile?: boolean,
	width1600?: boolean,
	fullWidth?: boolean,
}

const MainPageLayout = ({
	children, pageId = 'page',
	width1600 = false,
	fullWidth = false,
}: Props) => {
	const {
		magicModal: {
			model: {
				alignToTopOfWindow = false,
			} = {},
		} = {},
	} = useGlobalContext();

	const {
		autoCloseHeaderMobileAccountMenu = noop,
		autoCloseHeaderMobileSearch = noop,
		autoCloseModal = noop,
	} = useGlobalResets();

	if (!isEngage) {
		useLinkEvent();
	}

	autoCloseHeaderMobileAccountMenu();
	autoCloseHeaderMobileSearch();
	autoCloseModal();

	const pageBodyClasses = classNames(
		styles['page-body'],
		{ [styles['page-body-1600']]: width1600 },
		{ [styles['page-body-fullWidth']]: fullWidth },
	);

	return <>
		<div className={toolbarCss.toolbarWrapper}>
			<DevBeamWrapper />
			{
				isEngage &&
				<EngageToolbarWrapper />
			}
		</div>
		<FullHeader />
		<div id={pageId} className={classNames('page', { 'tw-mt-0': alignToTopOfWindow })}>
			<div data-qa="page-body" className={pageBodyClasses}>
				{children}
				<Footer />
			</div>
		</div>
	</>;
};

export const PrimaryLayout = ({
	children,
	pageId = 'page',
	stickyHeaderMobile = true,
	width1600 = false,
	fullWidth = false,
}: Props) => {
	return (
		<>
			<MainPageLayout pageId={pageId} stickyHeaderMobile={stickyHeaderMobile} width1600={width1600} fullWidth={fullWidth}>
				<main id="main-content" className={styles['main-content']}>
					{children}
				</main>
			</MainPageLayout>
			<MagicModal />
			<PersonalizationModal />
			<LiveChat />

			{/* third party scripts */}
			<AdobeTracking />
			<ReflektionScripts />
			<PublicGlobals />
			<NewRelic />
			<HumanSensor />
		</>
	);
};
