import Flicking from '@egjs/react-flicking';
import React, { useEffect, useRef, useState } from 'react';
import type {
	AfterResizeEvent, ChangedEvent, FlickingProps, HoldEndEvent, ReadyEvent, VisibleChangeEvent
} from '@egjs/react-flicking';
import cn from 'classnames';

import { DIRECTION, MOVE_TYPE, EVENTS } from '~/components/Slider/Slider.constants';
import { HomepageLinkItem } from '~/components/homepage-link-list/homepage-link-item/HomepageLinkItem';
import { inRange } from '~/util/inRange';
import { MagicHeaderTag } from '~/components/magic-header';
import { Slider } from '~/components/Slider/Slider';
import { SliderArrow } from '~/components/Slider/SliderArrow';
import { SHOPPING_CAROUSELS } from '~/components/shopping-carousel/ShoppingCarousel.types';
import { ShoppingCarouselData } from '~/components/shopping-carousel/ShoppingCarousel.constants';
import { SliderDots } from '~/components/Slider/SliderDots';
import { S7Image } from '~/components/Images/S7Image';
import {
	paginationSliderDotsNum, paginationEventSliderSyncIndex, sliderEventPaginationSyncIndex, updatePanels,
} from '~/components/Slider/Slider.utils';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

export const ShoppingCarousel = ({ shoppingComponentKey = undefined }: { shoppingComponentKey: keyof typeof SHOPPING_CAROUSELS | undefined }) => {
	if (!shoppingComponentKey) {
		return null;
	}

	const carouselData = ShoppingCarouselData[shoppingComponentKey] || undefined;

	if (!carouselData) {
		return null;
	}

	const {
		headline,
		links,
		panelsPerView,
	} = carouselData;

	const flickingRef = useRef<Flicking>(null);
	const sliderDotsFlickingRef = useRef<Flicking>(null);
	const panelCount = links.length;

	const [hideNavigation, setHideNavigation] = useState<Boolean>(true);

	const shouldSyncCarouselsRef = useRef(false);

	const navigationClass = cn({
		'tw-hidden': hideNavigation,
	});

	const sliderDotsPanelCount = paginationSliderDotsNum({
		sliderPanelsCount: panelCount,
		panelsPerView,
	});

	const [flickingProps, setFlickingProps] = useState<Partial<FlickingProps>>({
		moveType: [MOVE_TYPE.FREE_SCROLL, { count: 1 }],
		panelsPerView: 1.5,
		duration: 1000,
	});

	const { linkEventStore } = useGlobalContext();

	function handleAfterResize({
		prev: {
			width: prevWidth,
		},
		sizeChanged,
		width,
	}: AfterResizeEvent) {
		if (!sizeChanged) {
			return;
		}

		updateFlickingProps({
			prevWidth,
			width,
		});
	}

	function handleArrowClick(direction: DIRECTION) {
		const linkEventTrackingData = {
			trLinkEventCompName: headline,
			trLinkEventCompType: 'carousel shopping',
			trLinkEventName: `${direction === DIRECTION.PREV ? 'left' : 'right'} navigation`,
			trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
		};

		linkEventStore.trackLinkEvent(linkEventTrackingData);
	}

	function handleDotClick() {
		const linkEventTrackingData = {
			trLinkEventCompName: headline,
			trLinkEventCompType: 'carousel shopping',
			trLinkEventName: 'dot navigation',
			trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
		};

		linkEventStore.trackLinkEvent(linkEventTrackingData);
	}

	function handleHoldEnd({ currentTarget }: HoldEndEvent) {
		currentTarget.once('changed', ({
			direction,
			index,
		}) => {
			const linkEventTrackingData = {
				trLinkEventCompName: headline,
				trLinkEventCompType: 'carousel shopping',
				trLinkEventCompPosition: `${index + 1}:${panelCount}`,
				trLinkEventName: `${direction === DIRECTION.PREV ? 'left' : 'right'} navigation`,
				trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
			};

			linkEventStore.trackLinkEvent(linkEventTrackingData);
		});
	}

	function updateFlickingProps({
		prevWidth = 0,
		width = 0,
	}) {
		if (width >= 640) {
			shouldSyncCarouselsRef.current = true;
		} else {
			shouldSyncCarouselsRef.current = false;
		}

		if (width < 640 && prevWidth >= 640) {
			shouldSyncCarouselsRef.current = false;

			setFlickingProps({
				moveType: [MOVE_TYPE.FREE_SCROLL, { count: 1 }],
				panelsPerView: 1.5,
				duration: 1000,
			});

			setHideNavigation(true);
		}

		if (width > 640 && !inRange(prevWidth, 640, 1610)) {
			shouldSyncCarouselsRef.current = true;

			setFlickingProps({
				moveType: [MOVE_TYPE.STRICT, { count: 4 }],
				panelsPerView: 4,
			});

			setHideNavigation(panelCount <= 4);
		}
	}

	function handleReady({
		currentTarget: {
			panels,
			viewport: {
				width,
			},
			visiblePanels,
		},
	}: ReadyEvent) {
		updatePanels({
			panels,
			visiblePanels
		});

		updateFlickingProps({ width });
	}

	async function handleSliderChangedEvent({ index }: ChangedEvent) {
		if (shouldSyncCarouselsRef.current) {
			const targetIndex = sliderEventPaginationSyncIndex({
				currentIndex: index,
				panelsPerView,
			});

			await sliderDotsFlickingRef.current?.moveTo(targetIndex);
		}
	}

	async function handleSliderDotsPaginationChangedEvent({ index }: ChangedEvent) {
		if (shouldSyncCarouselsRef.current) {
			const targetIndex = paginationEventSliderSyncIndex({
				currentIndex: index,
				panelsPerView,
				sliderPanelsCount: panelCount,
			});

			await flickingRef.current?.moveTo(targetIndex);
		}
	}

	function handleVisibleChange({
		currentTarget: {
			panels,
		},
		visiblePanels,
	}: VisibleChangeEvent) {
		updatePanels({
			panels,
			visiblePanels,
		});
	}

	useEffect(() => {
		if (sliderDotsFlickingRef.current && flickingRef.current) {
			sliderDotsFlickingRef.current.on(EVENTS.CHANGED, handleSliderDotsPaginationChangedEvent);
			flickingRef.current.on(EVENTS.CHANGED, handleSliderChangedEvent);
		}

		return () => {
			if (sliderDotsFlickingRef.current && flickingRef.current) {
				sliderDotsFlickingRef.current.off(EVENTS.CHANGED, handleSliderDotsPaginationChangedEvent);
				flickingRef.current.off(EVENTS.CHANGED, handleSliderChangedEvent);
			}
		};
	}, []);

	return (
		<div data-tr-link-event-comp-name={headline} data-tr-link-event-comp-type="shopping carousel">
			<div>
				<div data-qa="shopping-carousel-headline">
					<MagicHeaderTag headerLevel="h2" className="tw-heading-4 md:tw-heading-2 tw-text-center tw-mb-4 md:tw-mb-10">{headline}</MagicHeaderTag>
				</div>
				<div className="tw-relative">
					<div className={`${navigationClass} tw-absolute tw-left-1 tw-z-[100] tw-bottom tw-bottom-1/2`} data-qa="shopping-carousel-prev">
						<SliderArrow
							aria-hidden={hideNavigation}
							buttonProps={{ isTransparent: false }}
							direction={DIRECTION.PREV}
							flickingRef={flickingRef}
							onClick={() => handleArrowClick(DIRECTION.PREV)}
						/>
					</div>
					<Slider
						circular={false}
						data-qa=""
						hideBeforeInit
						ref={flickingRef}
						resizeDebounce={100}
						align="prev"
						bound={true}
						{...flickingProps}
						onAfterResize={handleAfterResize}
						onHoldEnd={handleHoldEnd}
						onReady={handleReady}
						onVisibleChange={handleVisibleChange}
						preventDefaultOnDrag
						adaptive={false}
						className="tw--ml-2 md:tw--mx-2"
					>
						{links.map((link, index) => {
							const {
								caption = '',
								href = '',
								imageHeight = 360,
								imageWidth = 378,
								imageSrc = '',
							} = link;
							return (
								<div className="tw-p-2 tw-block" key={`slider-item-${index}`}>
									<HomepageLinkItem
										className="tw-flex tw-flex-col tw-text-left tw-flex-start"
										linkBlockClassName="tw-block"
										caption={caption}
										captionComponent="h3"
										captionSize="lg"
										href={href}
										trLinkEventCompPosition={`${index + 1}:${panelCount}`}
									>
										<S7Image
											alt=""
											height={imageHeight}
											src={imageSrc}
											width={imageWidth}
										/>
									</HomepageLinkItem>
								</div>
							);
						})}
					</Slider>
					<div className={`${navigationClass} tw-absolute tw-right-1 tw-z-[100] tw-bottom-1/2`} data-qa="shopping-carousel-next">
						<SliderArrow
							aria-hidden={hideNavigation}
							buttonProps={{ isTransparent: false }}
							direction={DIRECTION.NEXT}
							flickingRef={flickingRef}
							onClick={() => handleArrowClick(DIRECTION.NEXT)}
						/>
					</div>
				</div>
			</div>
			<div className={navigationClass} data-qa="shopping-carousel-dots">
				<SliderDots
					aria-hidden={hideNavigation}
					panelCount={sliderDotsPanelCount}
					ref={sliderDotsFlickingRef}
					onClick={handleDotClick}
				/>
			</div>
		</div>
	);
};
