import { CLEARANCE } from '~/components/hero/Components/CLEARANCE';
import { DINING } from '~/components/hero/Components/DINING';
import { ELY } from '~/components/hero/Components/ELY';
import { FRESH_FINDS } from '~/components/hero/Components/FRESH_FINDS';
import { LIVING } from '~/components/hero/Components/LIVING';
import { NEW_ARRIVALS } from '~/components/hero/Components/NEW_ARRIVALS';

export const Heroes = {
	'CLEARANCE': CLEARANCE,
	'DINING': DINING,
	'ELY': ELY,
	'FRESH_FINDS': FRESH_FINDS,
	'LIVING': LIVING,
	'NEW_ARRIVALS': NEW_ARRIVALS,
};
