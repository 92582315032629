import React from 'react';

import { Hero } from '~/components/hero/Hero';
import { HORIZONTAL_ALIGNMENT, IHeroWithBackgroundImage, VERTICAL_ALIGNMENT } from '~/components/hero/Hero.type';

export const ELY = () => {
	const heroArgs: IHeroWithBackgroundImage = {
		buttonText: 'Shop the Event',
		buttonLink: '/catalog/living/every-last-yard-event',
		headline: 'Every Last Yard',
		headlineClass: '',
		caption: '20-30% off limited quantities of seating & tables',
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgSrc: 'homepageHero_ely',
		imgHeight: 500,
		imgWidth: 500,
		headlineButtonContainerVerticalAlignment: VERTICAL_ALIGNMENT.TOP,
		nudgeHorizontal: 0,
		nudgeVertical: 10,
		imgNudgeHorizontalXS: -280,
	};

	return (
		<Hero { ...heroArgs } />
	);
};
