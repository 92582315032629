import Flicking from '@egjs/react-flicking';
import React, { forwardRef, useState } from 'react';

import type {
	AfterResizeEvent, FlickingProps, ReadyEvent, VisibleChangeEvent
} from '@egjs/react-flicking';
import type { ForwardedRef } from 'react';

import { updatePanels } from '~/components/Slider/Slider.utils';
import { inRange } from '~/util/inRange';

export const Slider = forwardRef((props: Partial<FlickingProps> & {
	sliderPropsByBreakpoints?: {
		breakpointMax: number,
		breakpointMin: number,
		sliderProps: Partial<FlickingProps>,
	}[],
}, ref: ForwardedRef<Flicking>) => {
	const {
		sliderPropsByBreakpoints = [],
		...sliderProps
	} = props;

	const [firstSliderPropsByBreakpoint] = sliderPropsByBreakpoints;

	const { sliderProps: initialSliderPropsByBreakpoint = {} } = firstSliderPropsByBreakpoint || {};

	const [flickingProps, setFlickingProps] = useState<Partial<FlickingProps>>(initialSliderPropsByBreakpoint);

	function updateFlickingProps({
		prevWidth = 0,
		width = 0,
	}) {
		const matchingSliderPropsByBreakpoint = sliderPropsByBreakpoints.find(({
			breakpointMax = 0,
			breakpointMin = 0,
		}, index = 0) => {
			return (index === 0 && width < breakpointMax && prevWidth >= breakpointMax)
				|| (inRange(width, breakpointMin, breakpointMax) && !inRange(prevWidth, breakpointMin, breakpointMax))
				|| (index + 1 === sliderPropsByBreakpoints.length && width >= breakpointMin && prevWidth < breakpointMin);
		});

		if (matchingSliderPropsByBreakpoint) {
			setFlickingProps(matchingSliderPropsByBreakpoint.sliderProps);
		}
	}

	function handleAfterResize(afterResizeEvent: AfterResizeEvent<Flicking>) {
		const {
			prev: {
				width: prevWidth,
			},
			sizeChanged,
			width,
		} = afterResizeEvent;

		if (!sizeChanged) {
			return;
		}

		props.onAfterResize?.(afterResizeEvent);

		updateFlickingProps({
			prevWidth,
			width,
		});
	}

	function handleReady(readyEvent: ReadyEvent<Flicking>) {
		const {
			currentTarget: {
				panels,
				viewport: {
					width,
				},
				visiblePanels,
			},
		} = readyEvent;

		props.onReady?.(readyEvent);

		updatePanels({
			panels,
			visiblePanels
		});

		updateFlickingProps({ width });
	}

	function handleVisibleChange(visibleChangeEvent: VisibleChangeEvent<Flicking>) {
		const {
			currentTarget: {
				panels,
			},
			visiblePanels,
		} = visibleChangeEvent;

		props.onVisibleChange?.(visibleChangeEvent);

		updatePanels({
			panels,
			visiblePanels,
		});
	}

	return (
		<Flicking
			{...sliderProps}
			onAfterResize={handleAfterResize}
			onReady={handleReady}
			onVisibleChange={handleVisibleChange}
			ref={ref}
			{...flickingProps}
		/>
	);
});
