import { s7ImagePath } from '~/global/global.constants';
import { IShoppingCarousel } from '~/components/shopping-carousel/ShoppingCarousel.types';

export const imageHeight = 360;

export const imageWidth = 386;

export const FRESH_FALL_24_DATA: IShoppingCarousel = {
	headline: 'What We Love Right Now',
	links: [
		{
			caption: 'Fresh Finds',
			href: '/catalog/living/fresh-finds',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_01`,
			imageWidth,
		},
		{
			caption: 'Remodeling Ideas',
			href: '/catalog/storage-and-entryway/remodeling-ideas',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_02`,
			imageWidth,
		},
		{
			caption: 'Sectionals',
			href: '/catalog/living/sectionals',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_03`,
			imageWidth,
		},
		{
			caption: 'Dining Tables',
			href: '/catalog/dining-and-kitchen/tables',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_04`,
			imageWidth,
		},
		{
			caption: 'Dining Chairs',
			href: '/catalog/dining-and-kitchen/chairs',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_05`,
			imageWidth,
		},
		{
			caption: 'Sleeper Sofas',
			href: '/catalog/living/sleeper-sofas',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_06`,
			imageWidth,
		},
		{
			caption: 'Lighting',
			href: '/catalog/lighting',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_07`,
			imageWidth,
		},
		{
			caption: 'Throw Blankets',
			href: '/catalog/home-decor/throw-blankets',
			imageHeight,
			imageSrc: `${s7ImagePath}/fresh_fall24_08`,
			imageWidth,
		},
	],
	panelsPerView: 4,
};

export const ShoppingCarouselData = {
	FRESH_FALL_24: FRESH_FALL_24_DATA,
};
