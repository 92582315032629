import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { MagicHeaderTag } from '~/components/magic-header';

export const ELYPromo = ({ clickHandler }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-gray tw-flex tw-justify-center tw-text-center tw-px-6 tw-py-10 tw-mb-1">
				<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px]">
					<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white">Every Last Yard</MagicHeaderTag>
					<p className="tw-body-2 tw-text-white">Get 20-30% off limited quantities of seating and tables.</p>
					<Link
						onClick={clickHandler}
						className=""
						color={ButtonColor.White}
						href="/catalog/living/every-last-yard-event"
						isTransparent
						variant={ButtonVariant.Hollow}
					>
					Shop the Event
					</Link>
				</div>
			</div>
		</>
	);
};
