import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { noop } from '~/util/noop';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';
import { personalzationModalSettings } from '~/personalization/Components/PersonalizationModal.constants';
import { PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const PersonalizationModal = observer(() => {
	const {
		accountNudgeModel,
		magicModal = {},
		personalizationStore = {},
		personalizationStore: {
			decisionsModel = undefined,
			sendDisplayEvent = noop,
			sendInteractEvent = noop,
		} = {},
	} = useGlobalContext();

	const openPersoanlizationModal = (decision: PersonalizationDecisionModel) => {
		if (!decision) {
			return;
		}

		const {
			componentKey,
			isMockDecision = false,
		} = decision;

		// We can trigger a mock modal decision from the dev beam Extra Options modal - so we need to close that modal first
		if (isMockDecision) {
			magicModal.closeModal();
		}

		const modalComponent = personalzationModalSettings[componentKey as keyof typeof personalzationModalSettings];

		if (!modalComponent) {
			console.warn(`No modalTypeSettings found for modal ${componentKey}`);
			return;
		}

		const isMediaQueryMd = window.matchMedia('(min-width: 40em)').matches;

		const modalProps = {
			isMediaQueryMd,
			interactEventHandler: () => {
				sendInteractEvent(decision);
			},
		};

		const modalSettingsWithProps = modalComponent ? modalComponent({
			isMediaQueryMd,
			modalProps,
		}) : {};

		setTimeout(() => {
			if (!magicModal.model.isOpen && !accountNudgeModel.showAccountNudge) {
				magicModal.openModal({
					onCloseModal: () => {
						magicModal.alterModal({
							containerClass: '',
							modalClassName: '',
						});
					},
					onOpenModal: () => {
						sendDisplayEvent(decision);
					},
					...modalSettingsWithProps,
				});
			}
		}, !isMockDecision ? 3000 : 500);
	};

	useEffect(() => {
		if (decisionsModel) {
			const decisionByScope = personalizationStore.getPersonalizationDecision(PERSONALIZATION_SCOPE.MODAL);

			if (decisionByScope) {
				const modalPersonalizationDecisionModel = new PersonalizationDecisionModel({});
				modalPersonalizationDecisionModel.setDecision(decisionByScope);

				openPersoanlizationModal(modalPersonalizationDecisionModel);
			}
		}
	}, [decisionsModel]);

	return <></>;
});
